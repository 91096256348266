import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: JohnsIsland Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectJohnsIslandPage() {
  return (
    <article
      id="page-portfolio-johns-island"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation"></sup>
            <div>John’s Island Club</div>
          </>
        }
        content={<>Vero Beach, FL</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-johns-island/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">Peacock + Lewis (Naples, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Designer</dt>
                <dd className="content">Ricca Design Studio (Denver, CO)</dd>
              </div>
              <div className="detail">
                <dt className="title">Design Consultant</dt>
                <dd className="content">
                  J. Banks Design Group (Hilton Head Island, SC)
                </dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">1,416</dd>
              </div>
            </dl>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-johns-island/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-johns-island/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-johns-island/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                White wine and Red Wine serving temperature displays.
              </li>
              <li className="feature">
                Invinity inspire collection wall-mounted double deep, with a
                satin black finish.
              </li>
              <li className="feature">
                Invinity inspire floor-to-ceiling double/single, with a satin
                black finish.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-johns-island/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <iframe
            src="https://player.vimeo.com/video/457810678"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "inspire-free-standing",
            title: "Inspire Collection",
            url: "products/inspire/free-standing/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-johns-island" />
    </article>
  )
}

export default ProjectJohnsIslandPage
